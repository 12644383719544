<template>
  <LcTabs
    v-bind="{ tabs, value }"
    adaptive-height
    trigger="manual"
    @update:value="navigateToTab"
  >
    <template #tab-panel="{ tab }">
      <NuxtPage v-if="showTabContent(tab.name)" :name="tab.name" />
    </template>
  </LcTabs>
</template>

<script lang="ts" setup>
import { LcTabs } from '@lottocom/frontend-components'

import { CUSTOM_PLAY_NOW_ROUTES } from '~/constants/playNowRoutes'

type PlayNowTabData = { path: string }

const { t } = useI18n()
const router = useRouter()

const { activeTabs: tabs } = useTabs<PlayNowTabData>([
  {
    data: { path: CUSTOM_PLAY_NOW_ROUTES.Lotteries.path },
    icon: 'tab-lotteries',
    name: CUSTOM_PLAY_NOW_ROUTES.Lotteries.name,
    title: t('playnow.tab.lotteries.title'),
  },
  {
    active: useIsScratchcardEnabled(),
    data: { path: CUSTOM_PLAY_NOW_ROUTES.Scratchcards.path },
    icon: 'tab-scratchcards',
    name: CUSTOM_PLAY_NOW_ROUTES.Scratchcards.name,
    title: t('playnow.tab.scratchcards.title'),
  },
])

const isClientThenPreload = ref(false)

const value = computed(() => String(router.currentRoute.value.name))

const navigateToTab = (name?: string) => {
  const nextTab = tabs.value.find((tab) => tab.name === name)
  if (!nextTab) return

  navigateTo(nextTab)
}

const showTabContent = (name: string) =>
  isClientThenPreload.value || value.value === name

onMounted(() => (isClientThenPreload.value = true))
</script>
